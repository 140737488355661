import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import Layout from "../components/layout";
import Map from "../components/map";

const MainContainer = styled(Container)`
  .map {
    padding-top: 40px;
  }
`;

// markup
const AboutUsPage = (data) => {
  const homePage = data.data.allSanityHomepage.nodes[0];

  return (
    <Layout title="About Us">
      <MainContainer>
        <Row>
          <Col sm={6}>
            <Container>
              <Row>
                <Col>
                  <h4>Operating Hours:</h4>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>Monday:</Col>
                <Col xs={8}>11 AM - 9 PM</Col>
              </Row>
              <Row>
                <Col xs={4}>Tuesday:</Col>
                <Col xs={8}>11 AM – 9 PM</Col>
              </Row>
              <Row>
                <Col xs={4}>Wednesday:</Col>
                <Col xs={8}>11 AM – 11 PM</Col>
              </Row>
              <Row>
                <Col xs={4}>Thursday:</Col>
                <Col xs={8}>11 AM – 10 PM</Col>
              </Row>
              <Row>
                <Col xs={4}>Friday:</Col>
                <Col xs={8}>7 AM – Midnight</Col>
              </Row>
              <Row>
                <Col xs={4}>Saturday:</Col>
                <Col xs={8}>7 AM – Midnight</Col>
              </Row>
              <Row>
                <Col xs={4}>Sunday:</Col>
                <Col xs={8}>9 AM – 9 PM</Col>
              </Row>
            </Container>
          </Col>
          <Col sm={6} style={{ padding: "20px 0px" }}>
            <h4>{homePage.address}</h4>
            <h4>{homePage.phone}</h4>
            Located in the Urban Union development in Arlington, TX we are right
            in the heart of all the exciting things happening for Downtown
            Arlington.
          </Col>
          <Col sm={12} className="map">
            <Map></Map>
          </Col>
        </Row>
      </MainContainer>
    </Layout>
  );
};

export const query = graphql`
  query AboutUsQuery {
    allSanityHomepage {
      nodes {
        name
        address
        state
        zip
        phone
        logo {
          ...ImageWithPreview
        }
      }
    }

    allSanitySpecial {
      nodes {
        description
        name
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`;

export default AboutUsPage;
