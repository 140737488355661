import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 32.738071,
  lng: -97.10261,
};

function Map() {
  return (
    <LoadScript googleMapsApiKey="AIzaSyAe8JXiivEFDsl0gvM-4WYfnXBIk15h-MM">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={12}>
        {/* Child components, such as markers, info windows, etc. */}
        <Marker key="loc" position={center} />
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(Map);
